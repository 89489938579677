import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
// import { NavLink, useNavigate } from "react-router-dom";
// import GoogleMapReact from 'google-map-react';

var bnrimg = require('./../../images/new-images/construction-1.jpg');
const bnr = require("./../../images/background/bg-map.png");

class ContactUs extends React.Component {
    render() {
        // const defaultProps = {
        //     center: {
        //       lat: 34.073280,
        //       lng: -118.251410
        //     },
        //     zoom: 12
        // };
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Contact Us" pagename="Contact us" description="We welcome your inquiries and are here to assist you. For any questions or to discuss your project needs, please don’t hesitate to get in touch with us." bgimage={bnrimg}/>
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                        <div
                style={{
                  backgroundImage: `url(${bnr})`,
                }}
              >
                <div className="contact-nav">
                  <div className="contact-nav-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-phone" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Phone number</h6>
                              <p>
                                <a href="tel:+971 2 6743939">+971 2 6743939</a>
                              </p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-fax" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Fax</h6>
                              <p>
                                <a href="tel:+971 2 6743936">+971 2 6743936</a>
                              </p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-envelope" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Email address</h6>
                              <p>
                                <a href="mailto:aldiygen@aldiygen.ae">
                                  aldiygen@aldiygen.ae
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-map-marker" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Address info</h6>
                              <p>Abu Dhabi , Tourist Club Area</p>
                              <p>Sarieddine Trading Building, Floor 0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                        </div>
                    </div>
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
              <div className="container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3630.719787943772!2d54.37290407535916!3d24.49516397816956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDI5JzQyLjYiTiA1NMKwMjInMzEuNyJF!5e0!3m2!1sen!2slb!4v1724961698097!5m2!1sen!2slb"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  title="Al Diyar Location"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ContactUs;