import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Team2 from "./../Elements/Team2";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const projects = [
  {
    image: [
      require("./../../images/projects/kickboxing/1.jpg"),
      require("./../../images/projects/kickboxing/2.jpg"),
      require("./../../images/projects/kickboxing/3.jpg"),
      require("./../../images/projects/kickboxing/4.jpg"),
      require("./../../images/projects/kickboxing/5.jpg"),
    ],
    title: "UAE Muay Thai & Kickboxing Federation",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-1",
  },
  {
    image: [require("./../../images/projects/JockeyClub/00.jpg"),require("./../../images/projects/JockeyClub/0.jpg"),require("./../../images/projects/JockeyClub/1.jpg"),require("./../../images/projects/JockeyClub/2.jpg"),require("./../../images/projects/JockeyClub/3.jpg"),require("./../../images/projects/JockeyClub/4.jpg"),require("./../../images/projects/JockeyClub/5.jpg"),require("./../../images/projects/JockeyClub/6.jpg"),require("./../../images/projects/JockeyClub/7.jpg"),require("./../../images/projects/JockeyClub/8.jpg"),require("./../../images/projects/JockeyClub/9.jpg"),require("./../../images/projects/JockeyClub/10.jpg"),require("./../../images/projects/JockeyClub/11.jpg"),],
    title: "Jockey Club",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-2",
  },
  {
    image: [require("./../../images/projects/StateAudit/1.jpg"),require("./../../images/projects/StateAudit/2.jpg"),require("./../../images/projects/StateAudit/3.jpg")],
    title: "State Audit Building",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-3",
  },
  {
    image: [require("./../../images/projects/AL-RAHA-C11-C12/1.jpg"),require("./../../images/projects/AL-RAHA-C11-C12/2.jpg"),require("./../../images/projects/AL-RAHA-C11-C12/3.jpg"),require("./../../images/projects/AL-RAHA-C11-C12/4.jpg"),],
    title: "AL RAHA C11/C12",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-4",
  },
  {
    image: [require("./../../images/projects/wadeema-yazia/1.jpg")],
    title: "Commercial Building Wadema and AL Yazia Building",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-5",
  },
  {
    image: [require("./../../images/projects/AL-RAHA-Building-Hannover/1.jpg"),require("./../../images/projects/AL-RAHA-Building-Hannover/2.jpg")],
    title: " AL RAHA Building Hannover",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-4",
  },
];

var bnrimg = require("./../../images/new-images/banner-8.jpg");

class ProjectGrid4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Our Projects"
            pagename="Our professional & skilled staff performs with quality, safety, and excellency to ensure the
optimal client’s satisfaction."
            description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
            bgimage={bnrimg}
          />

          <div className="section-full p-tb80 column-grid-4 inner-page-padding">
            <div className="container">
              <ul className="masonry-outer mfp-gallery row work-grid clearfix list-unstyled">
                {projects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-xl-4 col-lg-4 col-md-6 col-sm-12 m-b30`}
                  >
                    <OwlCarousel
                      className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center"
                      {...options}
                    >
                      {item.image.map((img, imgIndex) => {
                        return (
                          <div className="item" key={imgIndex}>
                            <div className="sx-box image-hover-block">
                              <div className="sx-thum-bx">
                                <img src={img} alt="" />
                              </div>
                              <div className="sx-info  p-t20 text-white">
                                <h4 className="sx-tilte">
                                {item.title}
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                ))}
              </ul>
            </div>
          </div>
          <Team2 />
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectGrid4;
