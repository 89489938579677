import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProjectGrid4 from './Pages/ProjectGrid4';
import BlogSingle from './Pages/BlogSingle';
import ContactUs from './Pages/ContactUs';

import ScrollToTop from "./Common/ScrollToTop";
import Home from "./Pages/Home";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/about-us" element={<BlogSingle />} />
            <Route path="/project" element={<ProjectGrid4 />} />
            <Route path='/contact-us' element={<ContactUs/>} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
