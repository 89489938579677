import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "./../Common/Header";

const Home = () => {
  const carouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    {
      src: require("./../../images/new-images/banner-6.jpg"),
      title: "Construction & Development",
      text: "Building Excellence, Engineering the Future.",
    },
    {
      src: require("./../../images/new-images/banner-1.jpg"),
      title: "Infrastructure Solutions",
      text: "Strength in Every Structure, Vision in Every Design.",
    },
    {
      src: require("./../../images/new-images/banner-3.jpg"),
      title: "Global Construction Solutions",
      text: "Innovating Today, Building for Generations.",
    },
    {
      src: require("./../../images/new-images/banner-4.jpg"),
      title: "Construction and Engineering Services",
      text: "Transforming Spaces, Elevating Standards.",
    },

    {
      src: require("./../../images/new-images/banner-2.jpg"),
      title: "Civil Engineering Solutions",
      text: "Engineering Precision, Building Trust.",
    },
    {
      src: require("./../../images/new-images/banner-5.jpg"),
      title: "Architectural & Structural Developments",
      text: "Designing with Vision, Building with Integrity.",
    },
  ];

  const handleScroll = useCallback(
    (direction) => {
      const newIndex =
        (activeIndex + direction + images.length) % images.length;
      setActiveIndex(newIndex);
      const scrollAmount = window.innerWidth * newIndex;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    [activeIndex, images.length]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      handleScroll(1);
    }, 3000);

    return () => clearInterval(interval);
  }, [handleScroll]);

  return (
    <div>
      <Header />
      <div className="carousel-container" ref={carouselRef}>
        <div className="carousel-wrapper">
          {images.map((item, index) => (
            <div
              className={`carousel-item image-single-carousel ${
                index === activeIndex ? "active" : ""
              }`}
              key={index}
            >
              <img src={item.src} alt={`Slide ${index + 1}`} />
              <div class="sx-info  p-t20 text-white carousel-body">
                <h4 class="sx-tilte m-t0">{item.title}</h4>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
