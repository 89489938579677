import React from 'react';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Import modules
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';

var bgimg1 = require('./../../images/new-images/construction-3.jpg');

const services = [
    {
      count: "01",
      title: "Construction and Engineering",
      description: "Specializing in construction and engineering for residential, commercial, and civil projects.",
    },
    {
      count: "02",
      title: "Civil Works",
      description: "Expert in civil works, blockwork, plastering, painting, tiling, flooring design, and crack repairs.",
    },
    {
      count: "03",
      title: "Additional Services",
      description: "AC maintenance, package units, electrical services, and annual maintenance contracts.",
    },
  ];

class Services extends React.Component {
    
    render() {
        return (
            <>
                <div className="section-full overlay-wraper sx-bg-secondry mobile-page-padding  p-t80 p-b50 mt-85  ml-auto" data-stellar-background-ratio="0.5" style={{ backgroundImage: 'url(' + bgimg1 + ')' ,backgroundPosition:"top"}}>
                        <div className="overlay-main bg-black opacity-05" />
                        <div className="container">
                        <div className="sx-separator-outer separator-left">
                <div className="sx-separator  bg-moving bg-repeat-x mision-vision-title">
                  <h3 className="sep-line-one" style={{ color: "white" }}>
                    Services
                  </h3>
                </div>
              </div>
                            <div className="section-content">
                            <div className="row number-block-two-outer">
      <Swiper
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={1} 
        pagination={true}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {services.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="col-12">
              <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                <div className="figcaption h-210 bg-white p-a30">
                  <h4 className="m-t0">{item.title}</h4>
                  <p>{item.description}</p>
                  {/* Uncomment if you want to use the link */}
                  {/* <NavLink
                    to={"/service/" + item.title.replaceAll(" ","-")}
                    className="site-button-link"
                  >
                    Read More
                  </NavLink> */}
                  <div className="figcaption-number animate-in-to-top-content">
                    <span>{item.count}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default Services;