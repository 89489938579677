import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Import modules
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';

const logos = [
    { image: require('./../../images/client-logo/client-1.jpg') },
    { image: require('./../../images/client-logo/client-2.jpg') },
    { image: require('./../../images/client-logo/client-3.jpg') },
    { image: require('./../../images/client-logo/client-4.jpg') },
    { image: require('./../../images/client-logo/client-5.jpg') }
]

var bgimg1 = require('./../../images/background/bg-12.jpg');

class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding bg-gray  p-t80 p-b10 bg-repeat" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                                <div className="sx-separator  bg-moving bg-repeat-x">
                                    <h3 className="sep-line-one">Our Clients</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b40">
                            <div className="row justify-content-center">
      <Swiper
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={2} 
        pagination={true}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 5 },
        }}
      >
        {logos.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="col-12">
              <img src={item.image} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Clients</strong>
                    </div> */}
                </div>
            </>
        );
    }
};

export default ClientsLogo1;