import React, { useEffect, useState, useCallback } from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About3 from "./../Elements/About3";
import Achievements1 from "../Elements/Achievements1";
import Statistics1 from "../Elements/Statistics1";
import ClientsLogo1 from "./../Elements/ClientsLogo1";
import Services from "../Elements/Services";
import ImageViewer from "react-simple-image-viewer";
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Pagination } from 'swiper/modules'; 
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';


var bnrimg = require("./../../images/new-images/construction-1.jpg");

const teamMembers = [
  {
    image: require("./../../images/our-team5/kamil.jpg"),
    membername: "Kamil",
    position: "",
  },
  {
    image: require("./../../images/our-team5/nabil.jpg"),
    membername: "Nabil",
    position: "",
  },
  {
    image: require("./../../images/our-team5/nadim.jpg"),
    membername: "Nadim",
    position: "",
  },
  {
    image: require("./../../images/our-team5/naji.jpg"),
    membername: "Naji",
    position: "",
  },
  {
    image: require("./../../images/our-team5/najib.jpg"),
    membername: "Najib",
    position: "",
  },
];

const awards = [
  {
    image: require("./../../images/certificates/certificates-1.jpg"),
  },
  {
    image: require("./../../images/certificates/certificates-2.jpg"),
  },
  {
    image: require("./../../images/certificates/certificates-3.jpg"),
  },
  {
    image: require("./../../images/certificates/certificates-4.jpg"),
  },
];

const BlogSingle = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showMore,setShowMore]=useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth > 991);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setWindowSize(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (e) => reject(e);
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };
    loadScript("./assets/js/custom.js");
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title="About Us"
          pagename="Blog Single"
          description="A multi-facetted company participating in a variety of industries. All two divisions of Al Diyar General Contracting provides superior service to clients with needs in Land Contracting & General Maintenance."
          bgimage={bnrimg}
        />
        <About3 bgcolor="bg-white" windowSize={windowSize} showMore={showMore} setShowMore={setShowMore} />
        <Achievements1 />
        <Statistics1 />
        <Services />
        <div className="section-full p-t80 p-b50 mobile-page-padding">
          <div className="container">
            <div className="section-content">
              <h3 className="m-t0 m-b20 sx-tilte">Our Partners</h3>
              <div className="row our-team-2-outer">
      <Swiper
               spaceBetween={10}
               slidesPerView={2}
               pagination={true} modules={[Pagination]}

        breakpoints={{
          640: { slidesPerView: 2 }, // Mobile view
          768: { slidesPerView: 3 }, // Tablet view
          1024: { slidesPerView: 5 }, // Larger viewports
        }}
      >
        {teamMembers.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="col-12 m-b30">
              <div className="our-team-2">
                <div className="profile-image">
                  <img src={item.image} alt={item.membername} />
                </div>
                <div className="figcaption text-black">
                  <h4 className="m-t0">{item.membername}</h4>
                  <span>{item.position}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
            </div>
          </div>
        </div>
        <ClientsLogo1 />
        <div className="section-full p-t80 p-b50 mobile-page-padding">
          <div className="container">
            <div className="section-content">
              <h3 className="m-t0 m-b20 sx-tilte">Licenses & Certificates</h3>
              <div className="row">
      <Swiper
        modules={[ Pagination]}
        spaceBetween={10}
        slidesPerView={2} 
        pagination={true}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 4 },
        }}
      >
        {awards.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="col-12">
              <div className="our-team-3">
                <div className="our-team-info image-hover-block">
                  <img
                    src={item.image}
                    onClick={() => openImageViewer(index)}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {isViewerOpen && (
        <ImageViewer
          src={awards.map(award => award.image)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          closeOnClickOutside={true}
        />
      )}
    </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogSingle;
