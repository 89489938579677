import React from "react";

var bgimg1 = require("./../../images/img521.jpg");

const About3 = ({ bgcolor, windowSize, showMore, setShowMore }) => {
  return (
    <div className={`${bgcolor} section-full mobile-page-padding p-t80 p-b50`}>
      <div className="container">
        <div className="section-content about-us-section">
          <div className="row">
            <div className="col-xl-7 col-md-12">
              <div className="about-home-3 m-b30 bg-white">
                <h3 className="m-t0 m-b20 sx-tilte">About Us</h3>
                <p>
                  Since its inception in 1993,{" "}
                  <strong style={{ color: "#15694a" }}>
                    Al Diyar General Contracting Company L.L.C
                  </strong>{" "}
                  has emerged as a leading name in the Abu Dhabi Emirates’ construction industry. With the steadfast dedication and expertise of our directors, we have achieved a top-tier 1st-Class Category ranking.
                </p>

                {
                  windowSize || showMore ? (
                    <>
                      <p>
                  For over 20 years, Al Diyar has been at the forefront of delivering a wide array of projects, from multi-storey commercial buildings and office spaces to luxury villas and military constructions. Our track record includes timely and successful completion of each project, all executed to the highest standards by our experienced team and skilled workforce. We have consistently transformed our clients' visions into tangible realities.
                </p>
                <p>
                  As we look to the future, Al Diyar is committed to enhancing quality and performance, staying aligned with international best practices, and pioneering innovation in the industry. Our dedicated staff continues to uphold our promise of excellence, safety, and client satisfaction.
                </p>
                {
                  !windowSize && showMore ? (
                    <div class="text-left"><button class="site-button-secondry btn-half" onClick={()=>setShowMore(false)}><span>Read Less</span></button></div>
                  ) : ""
                }
                    </>
                  ) : (
                    <div class="text-left"><button class="site-button-secondry btn-half" onClick={()=>setShowMore(true)}><span>Read More</span></button></div>
                  )
                }

              
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12">
              <div className="video-section-full-v2">
                <div
                  className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                  style={{ backgroundImage: `url(${bgimg1})` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About3;
