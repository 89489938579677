import React from "react";
import {useNavigate} from "react-router-dom"


const Navigation = () => {
  const navigate=useNavigate()
 
  return (
    <ul className="nav navbar-nav">
      <li className={` ${window.location.pathname === "/" ? "active" : ""}`} onClick={()=>navigate("/")}>
      <span
        >
          Home
       </span>
      </li>
      <li className={` ${window.location.pathname === "/about-us" ? "active" : ""}`}  onClick={()=>navigate("/about-us")}>
      <span
        >
          About us
      </span>
      </li>
      <li className={` ${window.location.pathname === "/project" ? "active" : ""}`}  onClick={()=>navigate("/project")}>
      <span
        >
          Projects
        </span>
      </li>
      <li className={` ${window.location.pathname === "/contact-us" ? "active" : ""}`}  onClick={()=>navigate("/contact-us")}>
        <span
        >
          Contact Us
        </span>
      </li>
    </ul>
  );
};

export default Navigation;
