import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const teamMembers = [
  {
    image: [
      require("./../../images/our-team5/ABU-Dhabi-Equestrian-Club/1.jpg"),
      require("./../../images/our-team5/ABU-Dhabi-Equestrian-Club/2.jpg"),
      require("./../../images/our-team5/ABU-Dhabi-Equestrian-Club/3.jpg"),
    ],
    membername: "ABU Dhabi Equestrian Club",
    position: "Design and Build",
  },
  {
    image: [
      require("./../../images/our-team5/Emarate-S/1.jpg"),
      require("./../../images/our-team5/Emarate-S/2.jpeg"),
    ],
    membername: "Emarate S",
    position: "Construction & Maintenance",
  },
  {
    image: [
      require("./../../images/our-team5/Building-of-H.H.Zayed-Bin/1.jpg"),
    ],
    membername: "Building of H.H. Zayed Bin Hemdan Bin Zayed Bin Nahyan",
    position: "Construction & Maintenance",
  },
  // {
  //     image: [require('./../../images/our-team5/2.jpg')],
  //     membername: 'Mark Norwich',
  //     position: 'Architect'
  // }
];

class Team2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-gray mobile-page-padding">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator  bg-moving bg-repeat-x">
                  <h3 className="sep-line-one">On Going Projects</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row justify-content-center">
                {teamMembers.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <OwlCarousel
                      className="owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right"
                      {...options}
                    >
                      {item.image.map((img, imgIndex) => (
                        <ImageWithPlaceholder key={imgIndex} src={img} />
                      ))}
                    </OwlCarousel>
                    <div className="our-team-content mt-5">
                      <h4 className="sx-team-name">
                        <a href="team-single.html">{item.membername}</a>
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// ImageWithPlaceholder Component
const ImageWithPlaceholder = ({ src }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="our-team-3">
      <div className="our-team-info" style={{ position: "relative" }}>
        {!isLoaded && (
          <div
            style={{
              backgroundColor: "#e0e0e0", // Grey background
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></div>
        )}
        <img
          src={src}
          alt=""
          style={{
            opacity: isLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in",
          }} // Smooth transition
          onLoad={() => setIsLoaded(true)}
        />
      </div>
    </div>
  );
};

export default Team2;
